import clone from "lodash.clonedeep";
import values from "lodash.values";
import { RULES, RULE_TYPE } from "../components/Form/automationRules";
import { buildModernAnswerKey, getClosestIndex, findElementByBothIds, getSliderValues } from "../utils/elementUtils";
import { TYPES } from "components/Element/elementTypes";

// Some rules have keys that are elements' legacyId's. This will change the
// keys to unique id's that are used to store answers.
export const reformatAutomationRules = (automations, orderedFormData) => {
	let newAutomations = clone(automations);
	newAutomations.forEach(automation => {
		if (automation.filter && automation.filter.groups)
		{
			automation.filter.groups.forEach(group => {
				group.rules = group.rules.map(rule => {
					// Update element rules keys to have same format as answer data in SPA
					if (!isOfficialRuleKey(rule.key))
					{
						const element = findElementByBothIds(orderedFormData, rule.key);
						if (element)
						{
							rule.legacyKey = rule.key;
							rule.key = buildModernAnswerKey(orderedFormData, rule.key);
							rule.type = RULE_TYPE.ANSWER;

							// Convert slider rules to used indices instead of values
							if (element.type === TYPES.SLIDER)
							{
								const sliderValues = getSliderValues(element);
								rule.value = getClosestIndex(rule.value, sliderValues);
							}
						}
						else
						{
							rule.type = RULE_TYPE.META;
						}
					}
					else
					{
						rule.type = RULE_TYPE.GENERAL;
					}
					return rule;
				});
			});
		}
	});
	return newAutomations;
};

const isOfficialRuleKey = (ruleKey) => {
	const ruleNames = values(RULES);
	return ruleNames.some(officialRuleKey => ruleKey === officialRuleKey);
};