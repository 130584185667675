const getMeta = (store) => {
	const answer = store.getState().answer;
	const safeMeta =
		answer.meta
		?
		answer.meta
		:
		[];

	return safeMeta.reduce((collectorObj, metaObj) => {
		collectorObj[metaObj.name] = metaObj.value;
		return collectorObj;
	}, {});
};

export default getMeta;