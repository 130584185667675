import {URL_PARAMETERS} from "../data/urlParser";

export function getTransitionSpeedMs(transitionSpeed)
{
	switch (transitionSpeed)
	{
		case URL_PARAMETERS.TRANSITION_SPEED_OPTION.SLOWEST:
			return 500;
		case URL_PARAMETERS.TRANSITION_SPEED_OPTION.SLOW:
			return 400;
		case URL_PARAMETERS.TRANSITION_SPEED_OPTION.DEFAULT:
			return 300;
		case URL_PARAMETERS.TRANSITION_SPEED_OPTION.FAST:
			return 200;
		case URL_PARAMETERS.TRANSITION_SPEED_OPTION.FASTEST:
			return 100;
		default:
			return 300;
	}
}