import {findElementByBothIds} from "../utils/elementUtils";
import {clearInput, deleteUpload} from "../components/Element/Input/answerActions";
import {TYPES} from "../components/Element/elementTypes";
import getAnswer from "./getAnswer";

function deleteAnswer(store, answerKey)
{
	const pages = store.getState().form.pages;
	const answer = store.getState().answer.answer;
	const element = findElementByBothIds(pages, answerKey);

	//If the element is an upload element, simply clearing the input causes internal server error when the answer is
	//created or saved. Therefore, instead of simply clearing the input, we run the upload deletion action.
	if (element.type === TYPES.NORMAL_UPLOAD || element.type === TYPES.IMAGE_UPLOAD || element.type === TYPES.VIDEO_UPLOAD || element.type === TYPES.AUDIO_UPLOAD)
	{
		getAnswer(store, answerKey).forEach(upload => store.dispatch(deleteUpload(element.id, upload.id, upload.id)));
		return;
	}

	Object.keys(answer).forEach((answerKey) => {
		if (answerKey.indexOf(element.id) === 0) store.dispatch(clearInput(answerKey));
	});
}

export default deleteAnswer;