/*
getQuestion will return this object: {
  question, //question text in the current language
  options, //array of options if the question is of such type, null if not
  answer, //the current answer (null if no answer)
  isAnswered //boolean marking if the question has an answer
}
*/
import {findElementByBothIds} from "../utils/elementUtils";
import {TYPES} from "../components/Element/elementTypes";
import getAnswer from "./getAnswer";

const getQuestion = (store, elementId) => {
	const state = store.getState();
	const currentLanguage = state.answer.language;
	const pages = state.form.pages;

	const element = findElementByBothIds(pages, elementId);
	const question = function(){
		switch (element.type)
		{
			case TYPES.SLIDER:
				return element.text[currentLanguage];
			default:
				return element.question[currentLanguage];
		}
	}();
	const options = function(){
		switch (element.type)
		{
			case TYPES.SINGLE_CHOICE:
			case TYPES.MULTI_CHOICE:
			case TYPES.DROPDOWN:
			case TYPES.SATISFACTION:
			case TYPES.IMAGE_SCALE:
			case TYPES.IMAGE_SELECTION:
			case TYPES.RANKORDER:
				return element.options.map(option => option.text[currentLanguage]);
			case TYPES.TABLE:
				return element.questions.map(question => question.text[currentLanguage]);
			case TYPES.SLIDER:
				return element.statements.map(statement => statement.text[currentLanguage]);
			default:
				return undefined;
		}
	}();
	const answer = getAnswer(store, elementId);
	const isAnswered = answer !== null;

	return {
		question,
		options,
		answer,
		isAnswered
	};
};

export default getQuestion;