import isNil from "lodash.isnil";

export function parseSafeFormat(element)
{
	return {
		stringLength: element.format && !isNil(element.format.stringLength) ? element.format.stringLength : 524288,
		numericDefault: element.format && !isNil(element.format.numericDefault) ? element.format.numericDefault : null,
		numericDecimals: element.format && !isNil(element.format.numericDecimals) ? element.format.numericDecimals : null,
		numericMin: element.format && !isNil(element.format.numericMin) ? element.format.numericMin : null,
		numericMax: element.format && !isNil(element.format.numericMax) ? element.format.numericMax : null,
		specificFormatExpressionType: element.format && !isNil(element.format.specificFormatExpressionType) ? element.format.specificFormatExpressionType : null,
		specificFormatExpression: element.format && !isNil(element.format.specificFormatExpression) ? element.format.specificFormatExpression : null,
		specificFormatFlags: element.format && !isNil(element.format.specificFormatFlags) ? element.format.specificFormatFlags : null,
		specificFormatErrorMessages: element.format && !isNil(element.format.specificFormatErrorMessages) ? element.format.specificFormatErrorMessages : null,
		type: element.format && !isNil(element.format.type) ? element.format.type : "string",
	};
}