import {
	putAnswer,
	postAnswer,
	answerCreated,
	answerSubmitted,
	setPersistState,
	DEFAULTANSWER,
	PERSIST_STATE,
	ANSWER_RESPONSE_STATUS
} from "../components/Element/Input/answerActions";

const apiSubmitAnswer = (answer, form, preview) => {
	return async dispatch => {
		console.log(`${preview ? "Preview response" : "Response"} being submitted through API...`);
		dispatch(setPersistState(PERSIST_STATE.BEING_SUBMITTED));

		const response =
			preview
			?
			{status: ANSWER_RESPONSE_STATUS.OK}
			:
			await putAnswer(answer, undefined, true, false);

		switch(response.status)
		{
			case ANSWER_RESPONSE_STATUS.OK:
				console.log("Response submitted through API.");
				dispatch(answerSubmitted(answer.answer, PERSIST_STATE.PERSISTED));
				break;

			case ANSWER_RESPONSE_STATUS.RESPONSE_UNACCEPTABLE:
				console.warn("Couldn't submit through API because answer limit is exceeded!");
				dispatch(answerSubmitted(answer.answer, PERSIST_STATE.FAILED));
				break;

			case ANSWER_RESPONSE_STATUS.NETWORK_ERROR:
				console.warn("Couldn't connect to server while submitting response!");
				dispatch(answerSubmitted(answer.submittedAnswer, PERSIST_STATE.NOT_PERSISTED));
				break;

			default:
				console.error("Response submit failed!");
				dispatch(answerSubmitted(answer.submittedAnswer, PERSIST_STATE.NOT_PERSISTED));
		}
	};
};

const apiCreateAndSubmitAnswer = (answer, form, page, preview) => {
	return async dispatch => {
		console.log(`${preview ? "Preview response" : "Response"} being created and submitted...`);
		const channel = form.urlParameters.channel;
		const sourceName = form.urlParameters.sourceName;
		const meta = form.urlParameters.meta;
		const extid = form.urlParameters.extid;
		const unique = form.urlParameters.unique;
		const anonymous = form.urlParameters.anonymous;
		const languageUuid = answer.language;
		dispatch(setPersistState(PERSIST_STATE.BEING_SUBMITTED));

		const response =
			preview
			?
				{
					status: ANSWER_RESPONSE_STATUS.OK,
					data: {
						answer: {
							id: DEFAULTANSWER.id,
							hash: DEFAULTANSWER.hash
						}
					}
				}
			:
			await postAnswer({
				surveyId: form.survey.id,
				hash: form.survey.hash,
				languageUuid,
				channel,
				sourceName,
				data: answer.answer,
				submit: true,
				currentPage: page,
				meta,
				extid,
				unique,
				anonymous
			});
		switch(response.status)
		{
			case ANSWER_RESPONSE_STATUS.OK:
			case ANSWER_RESPONSE_STATUS.CREATED:
				console.log("Response created and submitted through API.");
				const createdAnswer = response.data.answer;
				dispatch(answerCreated(createdAnswer.id, createdAnswer.hash, page, Date.now(), answer.answer, createdAnswer.resumable));
				dispatch(answerSubmitted(answer.answer, PERSIST_STATE.PERSISTED));
				break;

			case ANSWER_RESPONSE_STATUS.RESPONSE_UNACCEPTABLE:
				console.warn("Couldn't submit through API because answer limit is exceeded!");
				dispatch(answerSubmitted(answer.answer, PERSIST_STATE.FAILED));
				break;

			case ANSWER_RESPONSE_STATUS.NETWORK_ERROR:
				console.warn("Couldn't connect to server while creating and submitting response!");
				dispatch(answerSubmitted(answer.submittedAnswer, PERSIST_STATE.NOT_PERSISTED));
				break;

			default:
				console.error("Response submit failed!");
				dispatch(answerSubmitted(answer.submittedAnswer, PERSIST_STATE.NOT_PERSISTED));
		}
	};
};

/*
 Trigger a background submit, mainly used with integrations.
 USE THIS ONE WITH CAUTION and only when:
 - Channel allows answer editing after submit
 - There's no password for the survey
 Todo: Handle F-251 channel options when F-251 is in production
 */
const submit = (store) => {
	const answer = store.getState().answer;
	const page = answer.currentPage;
	const form = store.getState().form;
	const preview = form.urlParameters.preview;
	switch (answer.persistState)
	{
		case PERSIST_STATE.NOT_PERSISTED:
			store.dispatch(apiCreateAndSubmitAnswer(answer, form, page, preview));
			break;

		case PERSIST_STATE.PERSISTED:
			store.dispatch(apiSubmitAnswer(answer, form, preview));
			break;

		// Try later
		default:
			setTimeout(submit.bind(null, store), 2000);
	}
};

export default submit;