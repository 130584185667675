import { DEFAULT_LOCALE, LOAD_TRANSLATIONS, SET_LOCALE } from "./localesActions";
import cloneDeep from "lodash.clonedeep";

export const DEFAULT_STATE = {
  defaultLocale: DEFAULT_LOCALE,
  locale: DEFAULT_LOCALE,
  translations: {}
};

export default function(previousState = cloneDeep(DEFAULT_STATE), action)
{
  const nextState = cloneDeep(previousState);
  switch (action.type)
  {
    case SET_LOCALE:
      return {...nextState, locale: action.locale};
    case LOAD_TRANSLATIONS:
      const translations = action.translations;
      const translKeys = Object.keys(translations);
      const defaultKey = translKeys.find(translKey => translations[translKey].isDefaultLanguage);
      const defaultLocale = defaultKey ?
        translations[defaultKey].id :
        (
          translations[translKeys[0]].id ?
          translations[translKeys[0]].id :
          DEFAULT_LOCALE
        );
      return {...nextState, translations: action.translations, defaultLocale: defaultLocale};
    default:
      return previousState;
  }
}