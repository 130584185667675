import { defaultState, SET_PASSWORD_VISIBLE, SET_PASSWORD } from "./passwordActions" ;
import clone from "lodash.clonedeep";

// Use separate state branch for password to ensure it won't be saved
// to browser history in any circumstances
export default function(previousState = defaultState, action) {
	const state = clone(previousState);
	switch (action.type)
	{
		case SET_PASSWORD:
		{
			state.input = action.password;
			return state;
		}

		case SET_PASSWORD_VISIBLE:
		{
			state.inputVisible = action.visible;
			return state;
		}

		default:
			return state;
	}
};