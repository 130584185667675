import assign from "lodash.assign";
import clone from "lodash.clonedeep";
import merge from "lodash.merge";
import { DEFAULT_LOCALE } from "./localesActions";

/*
	This module creates locales for non-customizable messages in SPA forms.
  It also creates fallback locales for the customizable locales, to ensure that no survey breaks when new customizable messages are added.
  formlocales.js in Frontend module contains the same messages than the "*_formlocale.json" files.
 */

import DA from "./da_DK.json";
import DE from "./de_DE.json";
import EN from "./en_US.json";
import ES from "./es_ES.json";
import ES_LA from "./es_LA.json";
import FI from "./fi_FI.json";
import FR from "./fr_FR.json";
import IT from "./it_IT.json";
import JP from "./ja_JP.json";
import KO from "./ko_KR.json";
import NL from "./nl_NL.json";
import NO from "./nb_NO.json";
import PL from "./pl_PL.json";
import PT_BR from "./pt_BR.json";
import PT_PT from "./pt_PT.json";
import RU from "./ru_RU.json";
import SV from "./sv_SE.json";
import ZH from "./zh_CN.json";
import ZH_HK from "./zh_HK.json";

import DA_form from "./da_DK-formlocale.json";
import DE_form from "./de_DE-formlocale.json";
import EN_form from "./en_US-formlocale.json";
import ES_form from "./es_ES-formlocale.json";
import ES_LA_form from "./es_LA-formlocale.json";
import FI_form from "./fi_FI-formlocale.json";
import FR_form from "./fr_FR-formlocale.json";
import IT_form from "./it_IT-formlocale.json";
import JP_form from "./ja_JP-formlocale.json";
import KO_form from "./ko_KR-formlocale.json";
import NL_form from "./nl_NL-formlocale.json";
import NO_form from "./nb_NO-formlocale.json";
import PL_form from "./pl_PL-formlocale.json";
import PT_BR_form from "./pt_BR-formlocale.json";
import PT_PT_form from "./pt_PT-formlocale.json";
import RU_form from "./ru_RU-formlocale.json";
import SV_form from "./sv_SE-formlocale.json";
import ZH_form from "./zh_CN-formlocale.json";
import ZH_HK_form from "./zh_HK-formlocale.json";

const DA_merged = {[DA_form.key]: merge(clone(DA), clone(DA_form))};
const DE_merged = {[DE_form.key]: merge(clone(DE), clone(DE_form))};
const EN_merged = {[EN_form.key]: merge(clone(EN), clone(EN_form))};
const ES_merged = {[ES_form.key]: merge(clone(ES), clone(ES_form))};
const ES_LA_merged = {[ES_LA_form.key]: merge(clone(ES_LA), clone(ES_LA_form))};
const FI_merged = {[FI_form.key]: merge(clone(FI), clone(FI_form))};
const FR_merged = {[FR_form.key]: merge(clone(FR), clone(FR_form))};
const IT_merged = {[IT_form.key]: merge(clone(IT), clone(IT_form))};
const JP_merged = {[JP_form.key]: merge(clone(JP), clone(JP_form))};
const KO_merged = {[KO_form.key]: merge(clone(KO), clone(KO_form))};
const NL_merged = {[NL_form.key]: merge(clone(NL), clone(NL_form))};
const NO_merged = {[NO_form.key]: merge(clone(NO), clone(NO_form))};
const PL_merged = {[PL_form.key]: merge(clone(PL), clone(PL_form))};
const PT_BR_merged = {[PT_BR_form.key]: merge(clone(PT_BR), clone(PT_BR_form))};
const PT_PT_merged = {[PT_PT_form.key]: merge(clone(PT_PT), clone(PT_PT_form))};
const RU_merged = {[RU_form.key]: merge(clone(RU), clone(RU_form))};
const SV_merged = {[SV_form.key]: merge(clone(SV), clone(SV_form))};
const ZH_merged = {[ZH_form.key]: merge(clone(ZH), clone(ZH_form))};
const ZH_HK_merged = {[ZH_HK_form.key]: merge(clone(ZH_HK), clone(ZH_HK_form))};

let locales = {};
[
  DA_merged,
  DE_merged,
  EN_merged,
  ES_merged,
  ES_LA_merged,
  FI_merged,
  FR_merged,
  IT_merged,
  JP_merged,
  KO_merged,
  NL_merged,
  NO_merged,
  PL_merged,
  PT_BR_merged,
  PT_PT_merged,
  RU_merged,
  SV_merged,
  ZH_merged,
  ZH_HK_merged
].forEach(locale => {
    locales = assign(locales, locale);
});

export default locales;

export function getBestMatchingLocale(testLocaleKey)
{
  const localeKeys = [
    DA_form.key,
    DE_form.key,
    EN_form.key,
    ES_form.key,
    ES_LA_form.key,
    FI_form.key,
    FR_form.key,
    IT_form.key,
    JP_form.key,
    NL_form.key,
    PT_BR_form.key,
    RU_form.key,
    SV_form.key];
  const matchingLocale = localeKeys.find(key => key.indexOf(testLocaleKey) !== -1);
  return matchingLocale ? matchingLocale : DEFAULT_LOCALE;
}

export function overwriteInLocales(oldLocales, key, value)
{
  let newLocales = clone(oldLocales);
  Object.keys(newLocales).forEach(localeKey => {
    newLocales[localeKey][key] = value;
  });
  return newLocales;
}