import {isInteger} from "./apiUtils";

// Get current form page number
const getPage = (history) => {
	const splitPath = history.location.pathname.split("/");

	if (splitPath[2] && isInteger(parseInt(splitPath[2]))) return parseInt(splitPath[2]);

	if (splitPath[2] && isInteger(parseInt(splitPath[3]))) return parseInt(splitPath[3]);

	return NaN;
};

export default getPage;