import submit from "./submit";
import getPage from "./getPage";
import getLanguage from "./getLanguage";
import getMeta from "./getMeta";
import getQuestion from "./getQuestion";
import setAnswer from "./setAnswer";
import deleteAnswer from "./deleteAnswer";
import getAnswer from "./getAnswer";
import getAnswerId from "./getAnswerId";
import getPageType from "./getPageType";
import isElementVisible from "./isElementVisible";
import togglePrimary from "./togglePrimary";
import isPrimaryEnabled from "./isPrimaryEnabled";
import showSurveyError from "./showSurveyError";
import showAnswerError from "./showAnswerError";
import getState from "./getState";
import getHistory from "./getHistory";

const buildApi = (store, history) => ({
	submit: submit.bind(null, store),
	getPage: getPage.bind(null, history),
	getPageNumber: getPage.bind(null, history),
	getLanguage: getLanguage.bind(null, store),
	getMeta: getMeta.bind(null, store),
	getQuestion: getQuestion.bind(null, store),
	setAnswer: setAnswer.bind(null, store),
	deleteAnswer: deleteAnswer.bind(null, store),
	getAnswer: getAnswer.bind(null, store),
	getAnswerId: getAnswerId.bind(null, store),
	getPageType: getPageType.bind(null, history),
	isElementVisible: isElementVisible.bind(null, store),
	togglePrimary: togglePrimary.bind(null, store),
	isPrimaryEnabled: isPrimaryEnabled.bind(null, store),
	showAnswerError: showAnswerError.bind(null, store),
	showSurveyError: showSurveyError.bind(null, store),
	getState: getState.bind(null, store),
	getHistory: getHistory.bind(null, history)
});

export default buildApi;