import { defaultState, CREATE_NOTIFICATION, CLEAR_NOTIFICATION, TOGGLE_SLIDER_STATEMENT,
	SHOW_IMG_LOADER, SET_TRANSITION_DIRECTION, SET_CHOICE_OPEN_CACHE, TOGGLE_PAGE_TRANSITION } from "./UIActions";
import { UPLOAD_ADDED, UPLOAD_DELETED } from "components/Element/Input/answerActions";
import clone from "lodash.clonedeep";

export default function(previousState = defaultState, originalAction) {
	const state = clone(previousState);
	const action = clone(originalAction);
	switch (action.type)
	{
		case SET_TRANSITION_DIRECTION:
		{
			state.direction = action.direction;
			return state;
		}

		case SHOW_IMG_LOADER:
		{
			state.showImgLoader = action.show;
			return state;
		}

		case TOGGLE_PAGE_TRANSITION:
		{
			state.pageTransitionToggle = action.toggle;
			return state;
		}

		case CREATE_NOTIFICATION:
		{
			const newNotification = {
				id: action.id,
				subject: action.subject,
				type: action.notificationType,
				text: action.text,
				temporary: action.temporary
			};
			state.notifications.unshift(newNotification);
			return state;
		}

		case CLEAR_NOTIFICATION:
		{
			const notifications = state.notifications;
			const notificationIndex = notifications.findIndex(n => n.id === action.id);
			if (notificationIndex !== -1)
			{
				notifications.splice(notificationIndex, 1);
			}
			return state;
		}

		case SET_CHOICE_OPEN_CACHE:
		{
			state.choiceOpenCache[action.key] = action.value;
			return state;
		}

		case UPLOAD_ADDED:
		{
			state.fileCache[action.tempUploadId] = action.file;
			return state;
		}

		case UPLOAD_DELETED:
		{
			delete state.fileCache[action.uploadId];
			return state;
		}

		default:
			return state;
	}
}