import {
	ANSWER_RESPONSE_STATUS
} from "../components/Element/Input/answerActions";
import fixedPages from "../components/App/fixedPages";
import {
	redirect
} from "../components/Controls/UIActions";
import {
	FORM_RESPONSE_STATUS
} from "../components/Form/formActions";

const showAnswerError = (store, error) => {
	const location = window.location;
	const dispatch = store.dispatch;
	switch (error)
	{
		// Handle only errors that actually lead to error pages with answer requests
		case ANSWER_RESPONSE_STATUS.ANSWER_EXPIRED: // 406
		case fixedPages.ANSWER_EXPIRED.route:
			dispatch(redirect(location, fixedPages.ANSWER_EXPIRED.route));
			break;
		case FORM_RESPONSE_STATUS.INTERNAL_ERROR: // 500
		case fixedPages.INTERNAL_ERROR.route:
			dispatch(redirect(location, fixedPages.INTERNAL_ERROR.route));
			break;
	}
};

export default showAnswerError;