export default function injectStyles(style)
{
	const cssClass = "design-style";
	const cssIdPrefix = cssClass + "-";

	const styleElements = document.getElementsByClassName(cssClass);
	for(let i = 0; i < styleElements.length; i++)
	{
		styleElements[i].textContent = "";
	}

	Object.keys(style).forEach(function(key)
	{
		const existingStyleDOM = document.getElementById(cssIdPrefix + key);
		const newStyleDOM = existingStyleDOM != null ? existingStyleDOM : document.createElement("STYLE");
		if(existingStyleDOM == null)
		{
			newStyleDOM.id = cssIdPrefix + key;
			newStyleDOM.classList.add(cssClass);
			document.head.appendChild(newStyleDOM);
		}
		newStyleDOM.textContent = style[key];
	});
}