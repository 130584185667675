// Set answer should be only used to set a pre-selected answer, that is valid by default.
import {
	findElementByBothIds,
	getClosest,
	getSliderIndices,
	parseKeyParts
} from "../utils/elementUtils";
import {TYPES} from "../components/Element/elementTypes";
import {
	multipleChoiceChanged,
	openChanged,
	dateSelected,
	rankOrderChanged,
	singleChoiceSelected,
	validated
} from "../components/Element/Input/answerActions";
import isNil from "lodash.isnil";
import {
	generateScaleQuestionId,
	generateTableInputId
} from "../components/Element/Input/matrix-utils";
import {setChoiceOpenCache} from "../components/Controls/UIActions";
import {validateAllowEmpty} from "../utils/validationUtils";
import {parseSafeFormat} from "../components/Element/Input/openUtils";

const setAnswer = (store, answerKey, answer) => {
	const pages = store.getState().form.pages;
	const element = findElementByBothIds(pages, answerKey);
	const keyParts = parseKeyParts(answerKey);
	if (!element)
	{
		throw new Error(`Element with key ${keyParts.elementKey} couldn't be found!`);
	}
	switch (element.type)
	{
		case TYPES.SINGLE_CHOICE:
			//If the supplied answer is a string, it's probably an answer to an open option.
			if (typeof answer === "string")
			{
				setOpenOption(store, element, answerKey, answer);
				break;
			}
			validateAnswerType(element, answer, "number");
			validateIndex(element, element.options, answer);
			store.dispatch(singleChoiceSelected(element.id, answer, true));
			break;
		case TYPES.MULTI_CHOICE:
			//If the supplied answer is a string, it's probably an answer to an open option.
			if (typeof answer === "string")
			{
				setOpenOption(store, element, answerKey, answer);
				break;
			}
			if (typeof answer === "object" && answer.length && !answer.some(id => typeof id !== "number"))
			{
				answer.forEach((optionValue) => {
					validateAnswerType(element, optionValue, "number");
					validateIndex(element, element.options, optionValue);
					store.dispatch(multipleChoiceChanged(element.id, optionValue, true));
				});
				break;
			}
			validateAnswerType(element, answer, "number");
			validateIndex(element, element.options, answer);
			store.dispatch(multipleChoiceChanged(element.id, answer, true));
			break;
		case TYPES.DROPDOWN:
			validateAnswerType(element, answer, "number");
			validateIndex(element, element.options, answer);
			store.dispatch(singleChoiceSelected(element.id, answer, true));
			break;
		case TYPES.OPEN:
			validateAnswerType(element, answer, "string");
			setOpenAnswer(store, element, answer);
			break;
		case TYPES.DATE:
			validateAnswerType(element, answer);
			const date = new Date(answer);
			store.dispatch(dateSelected(element.id, date.getTime()));
			break;
		case TYPES.SLIDER:
			validateAnswerType(element, answer, "number");
			const sliderIndices = getSliderIndices(element);
			validateSliderIndex(element, sliderIndices, answer);
			validateKeyParts(element, keyParts, ["statement"], answerKey);
			setSliderAnswer(store, element, keyParts.statement, answer);
			break;
		case TYPES.IMAGE_SELECTION:
		case TYPES.IMAGE_SCALE:
		case TYPES.SATISFACTION:
			validateAnswerType(element, answer, "number");
			validateIndex(element, element.options, answer);
			setImageSelectionAnswer(store, element, answer);
			break;
		case TYPES.RANKORDER:
			validateRankOrderAnswer(element, answer);
			setRankOrderAnswer(store, element, answer);
			break;
		case TYPES.TABLE:
			validateKeyParts(element, keyParts, ["statement", "block"], answerKey);
			const block = element.blocks[keyParts.block];
			if (block.type === "scale")
			{
				validateAnswerType(element, answer, "number");
				validateIndex(element, block.values, answer);
				setScaleAnswer(store, element, keyParts.statement, keyParts.block, answer);
			}
			else
			{
				validateAnswerType(element, answer, "string");
				validateKeyParts(element, keyParts, ["column"], answerKey);
				setTableOpenAnswer(store, element, keyParts.statement, keyParts.block, keyParts.column, answer);
			}
			break;
		case TYPES.NORMAL_UPLOAD:
		case TYPES.IMAGE_UPLOAD:
		case TYPES.VIDEO_UPLOAD:
		case TYPES.AUDIO_UPLOAD:
			throw new Error(`Setting answer data for an upload element (${keyParts.elementKey}) is not supported by the api!`);
		default:
			throw new Error(`Element ${keyParts.elementKey} has invalid type ${element.type}!`);
	}
};

const validateAnswerType = (element, answer, targetType) => {
	if (element.type === TYPES.DATE)
	{
		try {
			const date = new Date(answer);
			date.toISOString();
		}
		catch(e)
		{
			throw new Error(`Answer type for ${element.type} element must be an ISO-format date string! Provided answer '${answer}' produces an invalid Date object.`);
		}
		return;
	}

	if (typeof answer !== targetType)
	{
		throw new Error(`Answer type for ${element.type} element must be a ${targetType}! Provided answer '${answer}' has type ${typeof answer}.`);
	}
};

const validateRankOrderAnswer = (element, answer) => {
	//Make sure the given answer is an array.
	if (!Array.isArray(answer))
	{
		throw new Error("Answer for Rank Order must be an array of numbers. Provided answer " + answer + " is of type " + typeof answer);
	}

	//Make sure the given answer is an array where all items are numbers.
	if (answer.some(item => typeof item !== "number"))
	{
		throw new Error("Answer for Rank Order must be an array of only numbers. Provided answer has items of other types.");
	}

	//If the rank order question is two-area (layout === "NEUTRAL"), the length of the given answer can be equal to or
	//less than the number of options. If the question is single-area, the length must equal the number of options.
	if (element.layout === "NEUTRAL" && answer.length > element.options.length)
	{
		throw new Error("Answer for a neutral-area Rank Order must have fewer or equal number of items as the question has options. Provided answer has " + answer.length + " items, the question has " + element.options.length + " options.");
	}
	if (element.layout !== "NEUTRAL" && answer.length !== element.options.length)
	{
		throw new Error("Answer for an active-area-only Rank Order must have the same number of items as the question has options. Provided answer has " + answer.length + " options, the question has " + element.options.length + " options.");
	}

	//Make sure all the given numbers are among the actual options.
	if (answer.some(item => item >= element.options.length))
	{
		throw new Error("Answer for Rank Order must only have items that are among the actual options. Provided answer has items that are not options in the question.");
	}

	//Make sure there are no duplicates in the given answer.
	if (answer.some((item, index) => answer.indexOf(item) !== index))
	{
		throw new Error("Answer for Rank Order must have all unique items. Provided answer has duplicates.");
	}
};

const validateKeyParts = (element, keyParts, targetKeyParts, answerKey) => {
	targetKeyParts.forEach(part => {
		if (isNil(keyParts[part]))
		{
			throw new Error(`Answer key for element ${element.id} of type ${element.type} is missing part '${part}'! Provided answer key was ${answerKey}.`)
		}
		else if (part !== "answerKey" && isNaN(keyParts[part]))
		{
			throw new Error(`Part '${part}' with value ${keyParts[part]} should be a number.`);
		}
	});
};

const validateIndex = (element, options, index) => {
	if (index >= options.length)
	{
		throw new Error(`Answer index ${index} is too large for element ${element.id}! The element has ${options.length} options`);
	}
	else if (index < 0)
	{
		throw new Error(`Answer index ${index} for element ${element.id} can't be negative!`);
	}
};

const validateSliderIndex = (element, options, index) => {
	if (index >= options.length)
	{
		throw new Error(`Answer index ${index} is too large for element ${element.id}! The element has ${options.length} options`);
	}
	else if (index < -1)
	{
		throw new Error(`Answer index ${index} for element ${element.id} can't be less than -1!`);
	}
};

const setScaleAnswer = (store, element, statement, block, answer) => {
	const scaleQuestionId = generateScaleQuestionId(element.id, statement, block);
	store.dispatch(singleChoiceSelected(scaleQuestionId, answer, true));
};

const setTableOpenAnswer = (store, element, statement, block, column, answer) => {
	const defaultFormat = {
		stringLength: 255,
		type: "string"
	};
	const answerKey = generateTableInputId(element.id, statement, block, column);
	const format = element.blocks[block].format ?
	               element.blocks[block].format :
	               defaultFormat;
	const validationResults = validateAllowEmpty(format, answer);
	store.dispatch(validated(answerKey, validationResults.valid, validationResults.validationError));
	store.dispatch(openChanged(answerKey, answer));
};

const setSliderAnswer = (store, element, statement, value) => {
	const statementKey = element.id + "/question:" + statement;
	const indices = getSliderIndices(element);
	const closestValidIndex = getClosest(value, indices);
	store.dispatch(singleChoiceSelected(statementKey, closestValidIndex, true));
};

const setImageSelectionAnswer = (store, element, answer) => {
	const type = element.type;
	const answerData = store.getState().answer.answer;
	const existingAnswer = answerData[element.id] ?
	                       answerData[element.id] : {};
	if((type === TYPES.SATISFACTION ||
	    type === TYPES.IMAGE_SCALE ||
	    element.maxSelection === 1) && existingAnswer.indices)
	{
		existingAnswer.indices.forEach(indexObj => store.dispatch(multipleChoiceChanged(element.id, indexObj.index, false)));
	}
	store.dispatch(multipleChoiceChanged(element.id, answer,true));
	return true;
};

const  setOpenAnswer = (store, element, value) => {
	const format = parseSafeFormat(element);
	const validationResults = validateAllowEmpty(format, value);
	let formattedValue = value;
	if(validationResults.valid && value !== "")
	{
		if(format.type === "numeric")
		{
			const floatValue = parseFloat(value);
			formattedValue = floatValue.toFixed(format.numericDecimals ? format.numericDecimals : 0);
		}
		store.dispatch(openChanged(element.id, formattedValue));
	}
	store.dispatch(validated(element.id, validationResults.valid, validationResults.validationError));
	return validationResults.valid;
};

const setRankOrderAnswer = (store, element, value) => {
	const answer = {
		type: "multi",
		indices: value.map((item, index) => {
				return {
					index: item,
					value: index
				};
			}
		)};
	store.dispatch(rankOrderChanged(element.id, answer));

	//If the rank order question is two-area (layout === "NEUTRAL") and marked as mandatory, an empty answer will not
	//be a valid answer. Otherwise any answer is valid.
	const valid = (element.layout === "NEUTRAL" && element.mandatory) ? (answer.length > 0) : true;
	store.dispatch(validated(element.id, valid));
	return valid;
};

const setOpenOption = (store, element, answerKey, answer) => {
	const openOptionIndex = (() => {
		//If a specific option was defined, see if we can put the answer into that option.
		const optionKey = answerKey.split(".")[1];
		if (!!optionKey && element.options[+optionKey]?.open)
		{
			return +optionKey;
		}

		//Otherwise put the answer into the first open option.
		return element.options.findIndex(findOpenOption);
	})();

	const key = `${element.id}-${openOptionIndex}`;
	store.dispatch(setChoiceOpenCache(key, answer));
	if (element.type === TYPES.SINGLE_CHOICE)
		store.dispatch(singleChoiceSelected(element.id, openOptionIndex, true, answer));

	if (element.type === TYPES.MULTI_CHOICE)
		store.dispatch(multipleChoiceChanged(element.id, openOptionIndex, true, answer));
};

const findOpenOption = (option) => option.open;

export default setAnswer;