import { buildModernAnswerKey, findElement } from "../utils/elementUtils";
import lodash_groupby from "lodash.groupby";
import { hasIndices, hasFiles, hasIndex, TYPES } from "../components/Element/elementTypes";

// Parse the answer response into local format
export function formatResponseAnswers(orderePagesAndElements, answersArray) {
	const improvedKeysAnswers = buildTableOpenKeys(orderePagesAndElements, answersArray);
	const improvedGroupedAnswers = lodash_groupby(improvedKeysAnswers, "elementId");

	let answerMap = {};
	Object.keys(improvedGroupedAnswers).forEach((key) => {
		const answersArray = improvedGroupedAnswers[key];
		const modernKey = buildModernAnswerKey(orderePagesAndElements, key);
		const element = findElement(orderePagesAndElements, key);

		// Use indices if answer can have multiple selections
		if (hasIndices(element)) {
			const indices = answersArray.map(parseIndexAndOrValueObject).sort(sortByValue);
			answerMap[modernKey] = {indices, type: "multi"};
		}
		// Collect file uploads
		else if (hasFiles(element)) {
			const filesArr = answersArray.map(parseLocalFileObject);
			const files = buildLocalFileMap(filesArr);
			answerMap[modernKey] = {files};
		}
		// Just one answer for this element
		else {
			answerMap[modernKey] = parseIndexAndOrValueObject(answersArray[0]);
		}
	});
	return answerMap;
}

export function formatURLAnswers(questionIdValuesObjArr) {
	let answerMap = {};
	questionIdValuesObjArr.forEach((questionIdValuesObj) => {
		const modernKey = questionIdValuesObj.element.id;

		// Use indices if answer can have multiple selections
		if (hasIndices(questionIdValuesObj.element)) {
			let indices;
			// questionIdValuesObj.value is array if same questionId appears multiple times in url
			if (Array.isArray(questionIdValuesObj.value)) {
				indices = questionIdValuesObj.value.map(someVal => { return {index: parseInt(someVal)}});
			}
			else {
				indices = [{index: parseInt(questionIdValuesObj.value)}];
			}
			answerMap[modernKey] = {indices, type: "multi"};
		}

		// Single valued elements
		else {
			const valOrIndex = Array.isArray(questionIdValuesObj.value) ? questionIdValuesObj.value[0] : questionIdValuesObj.value;
			if (hasIndex(questionIdValuesObj.element)) {
				answerMap[modernKey]  = {index: parseInt(valOrIndex)};
			}
			else {
				answerMap[modernKey] = {value: valOrIndex};
			}
		}
	});
	return answerMap;
}

function parseIndexAndOrValueObject (answer) {
	if (answer.value && answer.index !== undefined && answer.index !== null) {
		return {
			value: answer.value,
			index: answer.index
		};
	}
	else if (answer.value) {
		return {
			value: answer.value
		}
	}
	else if (answer.index !== undefined && answer.index !== null) {
		return {
			index: answer.index
		};
	}
	else {
		return null;
	}
}

//If the items contains values, they are Rank Order answers and need to be ordered by value.
function sortByValue(item1, item2)
{
	//The backend saves item values as strings, so here we convert them to numbers in order to compare them.

	if (+item1.value < +item2.value)
	{
		return -1;
	}
	if (+item1.value > +item2.value)
	{
		return 1;
	}
	return 0;
}

// Format response answers into local file objects
function parseLocalFileObject(answer) {
	if (answer.fileUpload) {
		const safeUrl = answer.fileUpload.cdnUrl && answer.fileUpload.hash ?
			answer.fileUpload.cdnUrl + "/" + answer.fileUpload.hash :
			"";
		return {
			id: answer.fileUpload.id,
			name: answer.fileUpload.name,
			size: answer.fileUpload.size,
			type: answer.fileUpload.contentType,
			url: safeUrl,
			uploadFinished: true,
			preview: null
		};
	}
}

function buildLocalFileMap(filesArray) {
	let filesMap = {};
	filesArray.forEach(file => {
		filesMap[file.id] = file;
	});
	return filesMap;
}

function buildTableOpenKeys(formattedPagesAndAnswer, answersArray) {
	return answersArray.map((answer) => {
		// Answer is table answer
		if (findElement(formattedPagesAndAnswer, answer.elementId).type === TYPES.TABLE) {

			// If answer is open table answer => needs subquestion identifier
			if (answer.index !== undefined && answer.index !== null && answer.value) {
				const newElemId = answer.elementId + ";"+answer.index;
				return {
					elementId: newElemId,
					value: answer.value
				};
			}
		}
		return answer;
	});
}