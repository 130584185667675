import { formatV1toV2 } from "../style/styleFormatter";
import { getElementsForRange } from "./pageUtils";
import { TYPES } from "../components/Element/elementTypes";

export function parseBgUrls(styleData = null)
{
	const isV1 = styleData.revision === "FORM_1";
	const style = isV1 ? formatV1toV2(styleData.dataJson) : styleData.dataJson ;

	const genericUrls = parseStyleBgUrls(style.generic);
	const deviceUrls = parseDeviceBgUrls(style.devices);

	return genericUrls.concat(deviceUrls);
}

function parseStyleBgUrls(style)
{
	const pBgKey = "page-background-image";
	const bgKey = "background-image";
	const valKey = "value";
	const urls =[];
	if (style[bgKey] &&
	    style[bgKey][valKey] &&
	    style[bgKey][valKey].indexOf("http") !== -1)
	{
		urls.push(style[bgKey][valKey])
	}
	if (style[pBgKey] &&
	    style[pBgKey][valKey] &&
			style[pBgKey][valKey].indexOf("http") !== -1)
	{
		urls.push(style[pBgKey][valKey]);
	}
	return urls;
}

function parseDeviceBgUrls(deviceStyles)
{
	return Object.keys(deviceStyles)
		.reduce((collector, key) => {
			return deviceStyles[key].enabled === true ?
			       collector.concat(parseStyleBgUrls(deviceStyles[key])) :
			       collector
		}, []);
}

export function parsePageImgUrls(page)
{
	const elems = getElementsForRange([page], 0, 0);
	return elems.reduce((collector, elem) => {
		switch(elem.type)
		{
			case TYPES.STATIC_IMAGE:
				return parseImgUrl(elem, "img", "src") ?
				       collector.concat([parseImgUrl(elem, "img", "src")]) :
				       collector;

			case TYPES.IMAGE_SELECTION:
			case TYPES.RANKORDER:
			case TYPES.SATISFACTION:
			case TYPES.IMAGE_SCALE:
				const optionUrls = elem.options.reduce((oCollector, opt) => {
					return parseImgUrl(opt, "image", "src") ?
					       oCollector.concat([parseImgUrl(opt, "image", "src")]) :
					       oCollector;
				}, []);
				return collector.concat(optionUrls);

			default:
				return collector;
		}
	}, []);
}

function parseImgUrl(container, imgPath, srcPath)
{
	return container &&
         container[imgPath] &&
         container[imgPath][srcPath] &&
         container[imgPath][srcPath].indexOf("http") !== -1 ?
         container[imgPath][srcPath] :
         null
}