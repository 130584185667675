import flattenDeep from "lodash.flattendeep";
import { isInputElement, HIDDEN_ELEMENT_TYPE } from "../components/Element/elementTypes";
import cloneDeep from "lodash.clonedeep";
import isNil from "lodash.isnil";

export function isOnLastPage(pageIndex, pages, hiddenElements)
{
	return getNextPage(pageIndex, pages, hiddenElements) >= pages.length;
}

export function getNextPage(page, pages, hiddenElements) {
	let pageToShow = page + 1;
	while(!(pageToShow >= pages.length) &&
	      !shouldPageBeShown(pages[pageToShow], hiddenElements))
	{
		pageToShow++;
	}
	return pageToShow;
}

export function getPreviousPage(page, pages, hiddenElements) {
	let pageToShow = parseInt(page);
	while(pageToShow > 0) {
		pageToShow--;
		if(shouldPageBeShown(pages[pageToShow], hiddenElements)) {
			break;
		}
	}
	return pageToShow;
}

export function shouldPageBeShown(page, hiddenElements)
{
	return !hiddenElements.some(hiddenObj => hiddenObj.type === HIDDEN_ELEMENT_TYPE.PAGE && hiddenObj.targetId === page.id);
}

export function hidePrimaryButtonForClickToSubmit(page)
{
	const everyInputHasChangeBySelection = page.rows.every(row => {
		const inputs = row.filter(isInputElement);
		return inputs.length === 0 ||
		       inputs.every(element => element.clickToSubmit);
	});
	const pageHasOnlyStatic = page.rows.every(row => {
		return row.filter(isInputElement).length === 0;
	});
	return !pageHasOnlyStatic && everyInputHasChangeBySelection;
}

export function getElementsForRange(originalPages, fromPageIndex, toPageIndex = fromPageIndex)
{
	const pages = cloneDeep(originalPages);
	// Check for exceptions
	if (isNil(fromPageIndex) || isNil(toPageIndex) || fromPageIndex > toPageIndex || fromPageIndex < 0 || toPageIndex < 0)
	{
		return [];
	}
	let elements = [];
	for (let i = fromPageIndex; i <= toPageIndex; i++)
	{
		const page = pages[i];
		const pageElements = flattenDeep(page.rows);
		elements.push(pageElements);
	}
	return flattenDeep(elements);
}

export function getElementIdsFromPage(originalPage)
{
	const page = cloneDeep(originalPage);

	let elementIds = [];

	const pageElements = flattenDeep(page.rows);
	for (let element of pageElements)
	{
		elementIds.push(element.id);
	}

	return flattenDeep(elementIds);
}

export function getPageIndexForElement(elementId, pages)
{
	return pages.findIndex(page => {
		return page.rows.some(row => {
			return row.some(elem => elem.id === elementId);
		});
	});
}

export function elementOrderComparator(pages, aId, bId)
{
	const allElements = getElementsForRange(pages, 0, pages.length - 1);
	return allElements.findIndex(e => e.id === aId) - allElements.findIndex(e => e.id === bId);
}