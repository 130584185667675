import {isInteger} from "./apiUtils";
import getPage from "./getPage";

const getPageType = (history) => {
	const splitPath = history.location.pathname.split("/");

	if ((splitPath[2] && isInteger(parseInt(splitPath[2]))) || (splitPath[3] && isInteger(parseInt(splitPath[3]))))
	{
		return getPage(history);
	}

	if (splitPath[3])
	{
		return splitPath[3];
	}

	return splitPath[2];
};

export default getPageType;