export const SET_LOCALE = "LOCALES.SET_LOCALE";
export const LOAD_TRANSLATIONS = "LOCALES.LOAD_TRANSLATIONS";
export const DEFAULT_LOCALE = "en_US";

export function setLocale(locale)
{
	return {
		type: SET_LOCALE,
		locale
	};
}

export function loadTranslations(translations)
{
	return {
		type: LOAD_TRANSLATIONS,
		translations
	};
}