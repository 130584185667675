import { getElementsForRange } from "./pageUtils";
import { hasFiles } from "../components/Element/elementTypes";

export function areAllUploadsFinished(pages, input)
{
	const uploadElemIds = getAllUploadElements(pages).map(elem => elem.id);
	return uploadElemIds.every(id => {
		const answerObj = input[id];
		return !answerObj ||
		       !answerObj.files ||
	         Object.keys(answerObj.files).every(fileKey => answerObj.files[fileKey].uploadFinished);
	});
}

export function getAllUploadElements(pages)
{
	return getElementsForRange(pages, 0, pages.length - 1).filter(hasFiles)
}