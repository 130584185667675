import {buildModernAnswerKey, findElementByBothIds} from "../utils/elementUtils";
import {TYPES} from "../components/Element/elementTypes";

const getAnswer = (store, target, params) => {
	const getStatementyAnswers = function(answerState, key) {
		const answers = Object.keys(answerState).reduce((carry, answerObjectKey) => {
			const split = answerObjectKey.split("/");
			if (split[0] === key)
			{
				return {
					...carry,
					[split[1]]: answer[answerObjectKey]
				}
			}
			return carry;
		}, {});

		//If there are no answer keys, question is not answered.
		if (Object.keys(answers).length === 0)
		{
			return null;
		}

		//If the question had answers but all answers were erased, the answer state will have residual answer keys with
		//empty objects. If there are answer keys but they are all empty, consider the question fully unanswered.
		if (Object.keys(answers).map((key) => answers[key]).filter(answer => Object.keys(answer).length !== 0).length === 0)
		{
			return null;
		}

		//Otherwise the question is answered.
		return answers;
	};

	const pages = store.getState().answer.pages;
	const language = store.getState().answer.language;
	const answer = store.getState().answer.answer;
	const element = findElementByBothIds(pages, target);

	//array is for gathering the indices for multi-choice elements.
	const array = [];
	let answerKey = buildModernAnswerKey(pages, target);

	switch (element.type)
	{
		case TYPES.SINGLE_CHOICE:
		case TYPES.DROPDOWN:
			if (answer[answerKey] && typeof answer[answerKey].index !== "undefined")
			{
				const open = element.options[answer[answerKey].index].open;
				return {
					index: answer[answerKey].index,
					text: element.options[answer[answerKey].index].text[language],
					value: open ? (answer[answerKey].value ? answer[answerKey].value : "") : null
				};
			}
			break;
		case TYPES.MULTI_CHOICE:
		case TYPES.IMAGE_SELECTION:
			if (answer[answerKey] && answer[answerKey].indices.length >= 1)
			{
				answer[answerKey].indices.forEach(function(item)
				{
					const open = element.options[item.index].open;
					array.push({
						index: item.index,
						text: element.options[item.index].text[language],
						value: open ? (item.value ? item.value : "") : null
					});
				});
				return array;
			}
			break;
		case TYPES.RANKORDER:
			//If there is an answer in the answer state, collect a return value from the answer state. It'll work for both
			//single-area and two-area rank orders.
			if (!(answerKey in answer) || answer[answerKey].indices.length === 0)
			{
				return null;
			}
			answer[answerKey].indices.forEach((item) => {
				array.push({
					index: item.index,
					text: element.options[item.index].text[language],
					image: element.options[item.index].image?.src
				})
			});
			return array;
		case TYPES.OPEN:
		case TYPES.DATE:
			if (answer[answerKey] && answer[answerKey].value)
			{
				const value = answer[answerKey].value;
				const date = new Date(value);
				const day = date.getDate();
				const month = date.getMonth() + 1;
				const year = date.getFullYear();
				switch (params)
				{
					case "object":
						return {
							date: date,
							fi_FI_string: `${day}.${month}.${year}`,
							en_US_string: `${month}/${day}/${year}`,
							day: day,
							month: month,
							year: year,
							timestamp: value
						};
					default:
						return value;
				}
			}
			break;
		case TYPES.SLIDER:
			//If statement isn't defined, return all answers for all statements
			if (target.indexOf(".") === -1)
			{
				return getStatementyAnswers(answer, answerKey);
			}
			let statement = parseInt(target.split(".")[1]);

			if (answer[answerKey] && typeof answer[answerKey].index !== "undefined")
			{
				return {
					index: answer[answerKey].index,
					label: element.statements[statement].text[language]
				};
			}
			break;
		case TYPES.IMAGE_SCALE:
		case TYPES.SATISFACTION:
			if (answer[answerKey] && answer[answerKey].indices.length >= 1)
			{
				return {
					index: answer[answerKey].indices[0].index,
					label: element.options[answer[answerKey].indices[0].index].text[language]
				};
			}
			break;
		case TYPES.TABLE:
			//If no statement is defined, return answers to all rows in all blocks
			if (target.indexOf(".") === -1)
			{
				return getStatementyAnswers(answer, answerKey);
			}

			let row = parseInt(target.split(".")[1].split("-")[0]);
			let block = parseInt(target.split("-")[1].split(";")[0]);
			let column = parseInt(target.split(";")[1]);

			if (answer[answerKey] && (typeof answer[answerKey].index !== "undefined" || typeof answer[answerKey].value !== "undefined"))
			{
				if (isNaN(column))
				{
					return {
						index: answer[answerKey].index,
						statement: element.questions[row].text[language],
						label: element.blocks[block].values[answer[answerKey].index].text[language]
					};
				}
				else
				{
					return {
						value: answer[answerKey].value,
						statement: element.questions[row].text[language],
						label: element.blocks[block].values[column].text[language]
					};
				}
			}
			break;
		case TYPES.NORMAL_UPLOAD:
		case TYPES.IMAGE_UPLOAD:
		case TYPES.VIDEO_UPLOAD:
		case TYPES.AUDIO_UPLOAD:
			if (answer[answerKey] && Object.keys(answer[answerKey].files).length >= 1)
			{
				Object.keys(answer[answerKey].files).forEach(function(item)
				{
					array.push({
						name: answer[answerKey].files[item].name,
						size: answer[answerKey].files[item].size,
						type: answer[answerKey].files[item].type,
						id: answer[answerKey].files[item].id,
						uploadFinished: answer[answerKey].files[item].uploadFinished,
						url: (answer[answerKey].files[item].url ? answer[answerKey].files[item].url : null)
					});
				});
				return array;
			}
			break;
	}

	return null;
};

export default getAnswer;