import values from "lodash.values";
import {FORM_RESPONSE_STATUS, getFormErrorRoute} from "../components/Form/formActions";
import {redirect} from "../components/Controls/UIActions";
import fixedPages from "../components/App/fixedPages";

const showSurveyError = (store, error) => {
	const location = window.location;
	const dispatch = store.dispatch;
	const statusCodes = values(FORM_RESPONSE_STATUS);
	if (statusCodes.includes(error))
	{
		try
		{
			const route = getFormErrorRoute(error);
			dispatch(redirect(location, route));
		}
		// 500 will throw an error from getFormErrorRoute
		catch(e)
		{
			dispatch(redirect(location, fixedPages.INTERNAL_ERROR.route));
		}
	}
	// Assume that error is a route string
	else
	{
		dispatch(redirect(location, error));
	}
};

export default showSurveyError;