import { getPageIndexForElement, getElementsForRange, getElementIdsFromPage } from "utils/pageUtils";
import { findElementByModernId } from "utils/elementUtils";
import { TYPES, HIDDEN_ELEMENT_TYPE } from "components/Element/elementTypes";
import isNil from "lodash.isnil";
import clone from "lodash.clonedeep";
import uniqBy from "lodash.uniqby";

export const LOGIC_ACTION = {
	SKIP_PAGE: "skipPage",
	GO_TO_PAGE: "gotoPage",
	SKIP_ELEMENT: "skipElement"
};

export function getLogicHiddenElements(pages, answerData)
{
	const skips = pages.reduce((pageCollector, page) => {
		const skipsForPage = page.rows.reduce((rowCollector, row) => {
			const skipsForRow = row.reduce((elemCollector, elem) => {
				const skipsForElement = applyElementLogic(elem, answerData, pages);
				return elemCollector.concat(skipsForElement);
			}, []);
			return rowCollector.concat(skipsForRow);
		}, []);
		return pageCollector.concat(skipsForPage);
	}, []);
	return uniqBy(skips, skip => removeDuplicateSkips(skip));
}

function removeDuplicateSkips(skip)
{
	if (String(skip.type) === "STATEMENT")
	{
		return String(skip.targetId) + String(skip.index);
	}
	else
	{
		return String(skip.targetId) + String(skip.type);
	}
}

function applyElementLogic(element, answerData, pages) {
	switch(element.type)
	{
		case TYPES.DROPDOWN:
		case TYPES.SINGLE_CHOICE:
		{
			if (!isNil(answerData[element.id]) && !isNil(answerData[element.id].index))
			{
				const index = answerData[element.id].index;
				const opt = element.options[index];
				return applyOptionLogic(opt, element.id, pages);
			}
			else
			{
				// No answer, no skips by this element
				return [];
			}
		}
		case TYPES.IMAGE_SCALE:
		case TYPES.SATISFACTION:
		case TYPES.MULTI_CHOICE:
		case TYPES.IMAGE_SELECTION:
		{
			if (!isNil(answerData[element.id]) && !isNil(answerData[element.id].indices))
			{
				const indices = answerData[element.id].indices;
				return indices.reduce((coll, indexObj) => {
					const index = indexObj.index;
					const opt = element.options[index];
					return coll.concat(applyOptionLogic(opt, element.id, pages));
				}, []);
			}
			else
			{
				// No answer, no skips by this element
				return [];
			}
		}
		// Other elements don't support old skip logic
	 	default:
			return [];
	}
}

function applyOptionLogic(opt, elementId, pages)
{
	if (opt.logic && opt.logic.length !== 0)
	{
		const [hidden, protectedPages] = opt.logic.reduce(([hidden, protectedPages], rule) => {
			switch(rule.action)
			{
				case LOGIC_ACTION.SKIP_ELEMENT:
				{
					if (!isNil(rule.target.index))
					{
						hidden.push({
							targetId: rule.target.element,
							type: HIDDEN_ELEMENT_TYPE.STATEMENT,
							index: rule.target.index
						});
					}
					else
					{
						hidden.push({targetId: rule.target.element, type: HIDDEN_ELEMENT_TYPE.ELEMENT});
					}
					return [hidden, protectedPages];
				}

				case LOGIC_ACTION.SKIP_PAGE:
				{
					hidden.push({targetId: rule.target.page, type: HIDDEN_ELEMENT_TYPE.PAGE});
					return [hidden, protectedPages];
				}

				case LOGIC_ACTION.GO_TO_PAGE:
				{
					const currentPageIndex = getPageIndexForElement(elementId, pages);
					protectedPages.push(rule.target.page);
					const endPageIndex = pages.findIndex(p => p.id === rule.target.page);
					for (let i = currentPageIndex + 1; i < endPageIndex; i++)
					{
						hidden.push({targetId: pages[i].id, type: HIDDEN_ELEMENT_TYPE.PAGE});
					}
					return [hidden, protectedPages];
				}

				default:
				{
					return [hidden, protectedPages];
				}
			}
		}, [[], []]);

		return hidden.filter(hiddenObj =>
			hiddenObj.type !== HIDDEN_ELEMENT_TYPE.PAGE ||
			!protectedPages.includes(hiddenObj.targetId)
		);
	}
	// No logic exists for this option
	else
	{
		return [];
	}
}

export function eraseHiddenAnswers(pages, oldAnswer, hiddenElements)
{
	const answer = clone(oldAnswer);
	// Some hidden elements are pages
	const hiddenElementIds = hiddenElements.reduce((collector, hiddenObj) => {
		const pageIndex = pages.findIndex(page => page.id === hiddenObj.targetId);
		if(hiddenObj.type === HIDDEN_ELEMENT_TYPE.PAGE && pageIndex !== -1)
		{
			const elemsInPage = getElementIdsFromPage(pages[pageIndex]);
			return collector.concat(elemsInPage);
		}
		else if (hiddenObj.type === HIDDEN_ELEMENT_TYPE.ELEMENT)
		{
			return collector.concat([hiddenObj.targetId]);
		}
		else if (hiddenObj.type === HIDDEN_ELEMENT_TYPE.STATEMENT)
		{
			return collector.concat([hiddenObj.targetId + "/question:" + hiddenObj.index]);
		}
		// In case there's some invalid rules, return collector to avoid fatal errors
		else
		{
			return collector;
		}
	}, []);

	Object.keys(answer).forEach(key => {
		if (hiddenElementIds.some(delId => key.indexOf(delId) !== -1))
		{
			const element = findElementByModernId(pages, key);
			if (element)
			{
				switch(element.type)
				{
					case TYPES.NORMAL_UPLOAD:
					case TYPES.IMAGE_UPLOAD:
					case TYPES.VIDEO_UPLOAD:
					case TYPES.AUDIO_UPLOAD:
						// answer[key] = {files: {}, type: "upload"};  This is suggested fix for P-3912, but doesn't work yet.
						break;
					case TYPES.SATISFACTION:
					case TYPES.IMAGE_SCALE:
					case TYPES.IMAGE_SELECTION:
					case TYPES.MULTI_CHOICE:
					case TYPES.RANKORDER:
						answer[key] = {indices: [], type: "multi"};
						break;
					default:
						answer[key] = {};
				}
			}
			else
			{
				answer[key] = {};
			}
		}
	});
	return answer;
}