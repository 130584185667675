import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk'
import form from "../components/Form/formReducers";
import locales from "../locales/localesReducer";
import UI from "../components/Controls/UIReducers";
import answer from "../components/Element/Input/answerReducers";
import password from "../components/Element/Input/passwordReducers";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
history.scrollRestoration = 'manual';

const historyInstance = createBrowserHistory();
export function getHistoryInstance()
{
	return historyInstance;
}

const store = createStore(combineReducers({
		form,
		locales,
		answer,
		UI,
		password,
		router: connectRouter(historyInstance)
	}),
	undefined,
	applyMiddleware(thunkMiddleware, routerMiddleware(historyInstance))
);
export function getStoreInstance()
{
	return store;
}