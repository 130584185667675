import randomId from "../../utils/randomId";
import { FIXED_ELEMENTS } from "../Element/elementTypes";

export const FIXED_PAGE_IDS = {
	ANSWER_ANONYMIZED: "answer_anonymized",
	START_LOADER: "start_loader",
	ANSWER_NOT_FOUND: "answer_not_found",
	ANSWER_LIMIT_EXCEEDED: "answer_limit_exceeded",
	ANSWER_EXPIRED: "answer_expired",
	FINISH_UPLOADS_LOADER: "finish_uploads_loader",
	SURVEY_NOT_ACTIVE: "survey_not_active",
	SURVEY_NOT_FOUND: "survey_not_found",
	INTERNAL_ERROR: "internal_error",
	MAINTENANCE: "maintenance",
	NETWORK_ERROR: "networks_error",
	SAVE_AND_RESUME: "save_and_resume",
	SAVE_AND_RESUME_THANKS: "save_and_resume_thanks",
	LANGUAGE_SELECTION: "language_selection",
	PASSWORD_FORM: "password_form",
	EMPTY: "empty",
	THANK_YOU: "thank_you",
	THANK_YOU_2: "thank_you_2",
	RESPONSE_LOCKED_REFRESH: "response_locked_refresh",
	RESPONSE_LOCKED: "response_locked"
};

export function isFixedPage(page) {
	const idValues = Object.keys(FIXED_PAGE_IDS).map(key => FIXED_PAGE_IDS[key]);
	return idValues.some(id => id === page.id);
}

export const PASSWORD_ELEMENT_ID = "com.surveypal.spa.password";
export const SUMMARY_EMAIL_ID = "com.surveypal.spa.summaryEmail";
export const SAVE_AND_RESUME_EMAIL_ID = "com.surveypal.spa.saveAndResumeEmail";
export const SURVEY_ACTIVE_RANGE = "com.surveypal.spa.surveyTimeRange";

export default {
	START_LOADER: {
		id: FIXED_PAGE_IDS.START_LOADER,
		value: "loadingStart",
		route: "start-loader",
		rows: [[]]
	},
	ANSWER_ANONYMIZED: {
		id: FIXED_PAGE_IDS.ANSWER_ANONYMIZED,
		route: "answer-anonymized",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "answerAnonymized"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "answerAnonymizedDescription"}]
		]
	},
	ANSWER_NOT_FOUND: {
		id: FIXED_PAGE_IDS.ANSWER_NOT_FOUND,
		route: "response-not-found",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "answerNotFoundTitle"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "answerNotFoundDescription"}]
		]
	},
	ANSWER_LIMIT_EXCEEDED: {
		id: FIXED_PAGE_IDS.ANSWER_LIMIT_EXCEEDED,
		route: "answer-limit-exceeded",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "surveyLimitReachedTitle"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "surveyLimitReached"}]
		]
	},
	ANSWER_EXPIRED: {
		id: FIXED_PAGE_IDS.ANSWER_EXPIRED,
		route: "answer-expired",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "answerExpiredTitle"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "answerExpiredDescription"}],
		]
	},
	SURVEY_NOT_ACTIVE: {
		id: FIXED_PAGE_IDS.SURVEY_NOT_ACTIVE,
		route: "survey-not-active",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "surveyclosedNoPeriod"}],
			[{type: FIXED_ELEMENTS.SURVEY_ACTIVE_RANGE, id: SURVEY_ACTIVE_RANGE}]
		]
	},
	SURVEY_NOT_FOUND: {
		id: FIXED_PAGE_IDS.SURVEY_NOT_FOUND,
		route: "survey-not-found",
		rows: [
			[{type: FIXED_ELEMENTS.ERROR_PAGE_LOGO, id: randomId()}],
			[{type: FIXED_ELEMENTS.FIXED_BIG_TITLE, id: randomId(), text: "surveyNotFoundTitle"}],
			[{type: FIXED_ELEMENTS.SURVEY_NOT_FOUND_CONTENT, id: randomId()}],
			[{type: FIXED_ELEMENTS.IF_PROBLEM_PERSISTS, id: randomId()}]
		]
	},
	INTERNAL_ERROR: {
		id: FIXED_PAGE_IDS.INTERNAL_ERROR,
		route: "internal-error",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "answerCreatingFailedTitle"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "answerCreatingFailedDescription"}],
		]
	},
	NETWORK_ERROR: {
		id: FIXED_PAGE_IDS.NETWORK_ERROR,
		route: "network-error",
		rows: [
			[{type: FIXED_ELEMENTS.ERROR_PAGE_LOGO, id: randomId()}],
			[{type: FIXED_ELEMENTS.FIXED_BIG_TITLE, id: randomId(), text: "oops"}],
			[{type: FIXED_ELEMENTS.NETWORK_ERROR_CONTENT, id: randomId()}],
			[{type: FIXED_ELEMENTS.IF_PROBLEM_PERSISTS, id: randomId()}]
		]
	},
	RESPONSE_LOCKED_REFRESH: {
		id: FIXED_PAGE_IDS.RESPONSE_LOCKED_REFRESH,
		route: "response-lock-refresh",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "responseAlreadyCompleted"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "responseRefresh"}]
		]
	},
	RESPONSE_LOCKED: {
		id: FIXED_PAGE_IDS.RESPONSE_LOCKED,
		route: "response-locked",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "responseAlreadyCompleted"}]
		]
	},
	SAVE_AND_RESUME: {
		id: FIXED_PAGE_IDS.SAVE_AND_RESUME,
		route: "save-and-resume",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "retAnonymousEmailTitle"}],
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "retAnonymousEmail"}],
			[{type: FIXED_ELEMENTS.SAVE_AND_RESUME_EMAIL, id: SAVE_AND_RESUME_EMAIL_ID, text: "emailAnswersLabel"}]
		]
	},
	SAVE_AND_RESUME_THANKS: {
		id: FIXED_PAGE_IDS.SAVE_AND_RESUME_THANKS,
		route: "save-and-resume-thanks",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "retAnonymousEmailThanks"}]
		]
	},
	LANGUAGE_SELECTION: {
		id: FIXED_PAGE_IDS.LANGUAGE_SELECTION,
		route: "language-selection",
		rows: [
			[{type: FIXED_ELEMENTS.LANGUAGE_SELECTION_TITLE, id: randomId(), text: "chooseLanguage"}],
			[{type: FIXED_ELEMENTS.LANGUAGE_SELECTION, id: randomId()}]
		]
	},
	PASSWORD_FORM: {
		id: FIXED_PAGE_IDS.PASSWORD_FORM,
		route: "password",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_TITLE, id: randomId(), text: "passwordTitle"}],
			[{type: FIXED_ELEMENTS.PASSWORD, id: PASSWORD_ELEMENT_ID, text: "passwordLabel"}]
		]
	},
	FINISH_UPLOADS_LOADER: {
		id: FIXED_PAGE_IDS.FINISH_UPLOADS_LOADER,
		focusOnText: true,
		route: "finishing-uploads",
		value: "finishingUploads",
		rows: [[]]
	},
	THANK_YOU: {
		id: FIXED_PAGE_IDS.THANK_YOU,
		route: "thank-you",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "finalpage"}]
		]
	},
	EMPTY: {
		id: FIXED_PAGE_IDS.EMPTY,
		route: "thank-you-empty",
		rows: []
	},
	THANK_YOU_WITH_SUMMARY: {
		id: FIXED_PAGE_IDS.THANK_YOU,
		route: "thank-you",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "finalpage"}],
			[{type: FIXED_ELEMENTS.SUMMARY_EMAIL, id: SUMMARY_EMAIL_ID, text: "emailAnswersLabel"}]
		]
	},
	THANK_YOU_WITH_SUMMARY_2: {
		id: FIXED_PAGE_IDS.THANK_YOU_2,
		route: "thank-you-2",
		rows: [
			[{type: FIXED_ELEMENTS.FIXED_DESCRIPTION, id: randomId(), text: "emailAnswersSent"}]
		]
	},
	MAINTENANCE: {
		id: FIXED_PAGE_IDS.MAINTENANCE,
		route: "maintenance",
		rows: [
			[{type: FIXED_ELEMENTS.MAINTENANCE, id: randomId(), text: "maintenance"}]
		]
	}
};