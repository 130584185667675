export default {
	// Allowed tags based on https://www.w3schools.com/tags/ref_byfunc.asp
	allowedTags: [
		// Basic structural tags
		'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'br', 'hr',

		// Styles and Semantics
		'div', 'span', 'style',
		'header', 'footer', 'main', 'section', 'article', 'aside', 'details', 'dialog', 'summary', 'data',

		// Lists
		'ul', 'ol', 'li', 'dl', 'dt', 'dd',

		// Formatting
		'abbr', 'address', 'b', 'bdi', 'bdo', 'blockquote', 'cite', 'code', 'del', 'dfn', 'em', 'i', 'ins', 'kbd', 'mark',
		'meter', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'small', 'strong', 'strike', 'sub', 'sup', 'template',
		'time', 'u', 'var', 'wbr',

		// Forms
		'input', 'textarea', 'button', 'select', 'optgroup', 'option', 'label', 'fieldset',' legend', 'datalist', 'output',

		// Table
		'table', 'caption', 'th', 'tr', 'td', 'thead', 'tbody', 'tfoot', 'col', 'colgroup',

		// Images
		'img', 'map', 'area', 'canvas', 'figcaption', 'figure', 'picture', 'svg',

		// Audio & video
		'audio', 'source', 'track', 'video',

		// Links
		'a', 'link', 'nav',

		/*
			Not allowed:
			-Any non-html5 tag
			-One time elements and meta: 'html', 'head', 'title', 'body', 'meta', 'base',
			-'iframe'', 'script', 'noscript', 'form', 'embed', 'object', 'param'
		 */
	],
	// Set to suppress warnings. We're e.g. allowing style tags in html snippets so this is needed.
	allowVulnerableTags: true,

	// Attributes based on MDN documentation https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes
	// Shortly, all attributes except those related to invalid tags are allowed. Legacy attributes are not allowed.
	// Experimental attributes are generally allowed.
	allowedAttributes: {
		'*': [
			'accept', 'accesskey', 'align', 'alt', 'autocomplete', 'autofocus', 'autoplay',
			'buffered',
			'checked', 'cite', 'class', 'cols', 'colspan', 'contenteditable', 'contextmenu', 'controls', 'coords', 'crossorigin',
			'data', 'data-*', 'datetime', 'decoding', 'default', 'dir', 'dirname', 'diabled', 'download', 'draggable', 'dropzone',
			'for', 'formation',
			'headers', 'hidden', 'high', 'href', 'hreflang',
			'id', 'integrity', 'importance', 'ismap', 'itemprop',
			'kind',
			'label', 'lang', 'lazyload', 'list', 'loop', 'low',
			'max', 'maxlength', 'minlength', 'media', 'min', 'multiple', 'muted',
			'name',
			'open', 'optimum',
			'pattern', 'ping', 'placeholder', 'poster', 'preload',
			'readonly', 'referrerpolicy', 'rel', 'required', 'reversed', 'rows', 'rowspan',
			'scope', 'scoped', 'selected', 'shape', 'sizes', 'slot', 'span', 'spellcheck', 'src', 'srclang', 'srcset', 'start', 'step', 'style', 'summary',
			'tabindex', 'target', 'title', 'translate', 'type', 'usemap', 'value', 'wrap',
			'aria-required', 'aria-hidden', 'role'
		]
	}
};