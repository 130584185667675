function reformatFormData(formData)
{
	let pageIndex = 0;
	const pages = [];
	formData.order.map(function(pageId)
	{
		pages.push({
			id: pageId,
			rows: reformatPageData(formData.pages[pageId], pageIndex++)
		});
	}.bind(this));
	return pages;
}

function reformatPageData(pageData, pageIndex)
{
	let elementIndex = 0;
	const rows = [];
	pageData.order.map(function(orderRow)
	{
		const row = [];
		orderRow.map(function(elementId)
		{
			let element = pageData.elements[elementId];
			element.legacyId = "p" + pageIndex + "e" + elementIndex++;
			row.push(element);
		});
		rows.push(row);
	});
	return rows;
}

export default reformatFormData;