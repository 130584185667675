import {findElementByBothIds} from "../utils/elementUtils";

const isElementVisible = (store, target) => {
	const pages = store.getState().answer.pages;
	const hiddenElements = store.getState().answer.hiddenElements;

	//Assume that the given target is an element ID, and we aren't checking the visibility of a page.
	let elementId = target;
	let pageId = null;

	//In case the given target starts with a page number instead, find page ID and element ID for searching from the
	// hidden elements list.
	if (/^p\d+.*/.test(target))
	{
		const pageNumber = parseInt(target.split("e")[0].substring(1));
		pageId = pages[pageNumber].id;

		//If there's also an element number, get the element ID.
		if (/^p\d+e\d+$/.test(target))
		{
			elementId = findElementByBothIds(pages, target).id;
		}
	}

	//Then find if the ID is on the hidden elements list.
	for (let hiddenElement = 0; hiddenElement < hiddenElements.length; hiddenElement++)
	{
		if (hiddenElements[hiddenElement].targetId === elementId || hiddenElements[hiddenElement].targetId === pageId)
		{
			//Return false if the ID is on the element list; it is not visible.
			return false;
		}
	}
	return true;
};

export default isElementVisible;