export const defaultState = {
	input: "",
	inputVisible: false
};

export const SET_PASSWORD_VISIBLE = "SET_PASSWORD_VISIBLE";
export const SET_PASSWORD = "SET_PASSWORD";

export function setPassword(password) {
	return {
		type: SET_PASSWORD,
		password
	}
}

export function setPasswordInputVisible(visible) {
	return {
		type: SET_PASSWORD_VISIBLE,
		visible
	}
}