import clone from "lodash.clonedeep";

export function buildModernAnswerKey(orderedFormData, ruleKey) {

	// First find the matching element id for the automation
	let modernRuleKey = findElementByBothIds(orderedFormData, ruleKey).id;

	// If the key has stuff still after the dot
	const strAfterDot = ruleKey.split(".")[1];
	if (strAfterDot) {
		const firstPartAfterPeriod = strAfterDot.split("-")[0];
		const secondPartAfterPeriod = strAfterDot.split("-")[1];
		modernRuleKey += "/question:"+firstPartAfterPeriod;

		// If key has still stuff after the dash
		if (secondPartAfterPeriod) {
			const firstPartAfterDash = secondPartAfterPeriod.split(";")[0];
			const secondPartAfterDash = secondPartAfterPeriod.split(";")[1];
			modernRuleKey += "-block:"+firstPartAfterDash;

			if (secondPartAfterDash) {
				modernRuleKey += "-column:"+secondPartAfterDash;
			}
		}
	}

	return modernRuleKey;
}

// matcherFunc is a function that gets "matcherArg1" and an element
// as arguments. Will return a boolean that tells if those two are a match.
export function genericFindElement(matcherFunc, matcherArg1, orderedFormData) {
	let matchingElem = null;

	// Iterate through pages, rows and elements to find the correct element
	for (let i = 0; i < orderedFormData.length; i++) {
		if (!matchingElem) {
			const page = orderedFormData[i];
			for (let j = 0; j < page.rows.length; j++) {
				const row = page.rows[j];
				if (!matchingElem) {
					for (let k = 0; k < row.length; k++) {
						const element = row[k];
						if (!matchingElem) {
							matchingElem = (matcherFunc(matcherArg1, element) ? element : null);
						}
						else {
							break;
						}
					}
				}
				else {
					break;
				}
			}
		}
		else {
			break;
		}
	}

	return matchingElem;
}

export function findElement (orderedFormData, ruleKey) {
	const matcherFunc = function(key, element) {
		return key === element.legacyId;
	};
	// First part of rule key is always element legacy id
	const ruleElementKey = ruleKey.split(".")[0];
	return genericFindElement(matcherFunc, ruleElementKey, orderedFormData);
}

export function findElementByQuestionId(orderedFormData, customId) {
	const matcherFunc = function(id, element) {
		return id === element.questionId;
	};
	return genericFindElement(matcherFunc, customId, orderedFormData);
}

export function findElementByBothIds(orderedFormData, id) {
	const element = findElement(orderedFormData, id);
	return element ? element : findElementByQuestionId(orderedFormData, id);
}

export function findElementByModernId(orderedFormData, id) {
	const matcherFunc = function(modernId, element) {
		return modernId === element.id;
	};
	return genericFindElement(matcherFunc, id, orderedFormData);
}

// Slider related functions
export function getSliderValues(slider)
{
	const values = [];
	const step = (slider.maxValue - slider.minValue) / slider.stepCount;
	for(let i = 0; i < slider.stepCount + 1; i++)
	{
		values.push(slider.minValue + (i * step));
	}
	return values;
}

export function getSliderIndices(slider)
{
	return getSliderValues(slider).map((_, i) => i);
}

export function getSliderLabels(slider)
{
	const labels = [];
	const step = (slider.maxValue - slider.minValue) / slider.gridCount;
	for (let i = 0; i <= slider.gridCount; i++)
	{
		let label = slider.minValue + (i * step);
		// The point of the if-else is to ensure there's always correct amount of fractional digits visible
		if (label.toFixed(1) === parseInt(label).toFixed(1))
		{
			labels.push(parseInt(label));
		}
		else

		{
			labels.push(label.toFixed(1));
		}
	}
	return labels;
}

export function getClosest(value, array)
{
	//-1 means that the answer is left empty
	if (value === -1) return value;

	return clone(array).sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
}

export function getClosestIndex(value, array)
{
	const closest = getClosest(value, array);
	return array.findIndex(val => val === closest);
}

export function parseKeyParts(key)
{
	const resultObj = {};
	resultObj.elementKey = key.split(".")[0];
	const afterDot = key.split(".")[1];
	if (afterDot)
	{
		resultObj.statement = Number(afterDot.split("-")[0]);
		const afterDash = afterDot.split("-")[1];
		if (afterDash)
		{
			resultObj.block = Number(afterDash.split(";")[0]);
			const afterSemicolon = afterDash.split(";")[1];
			if (afterSemicolon)
			{
				resultObj.column = Number(afterSemicolon);
			}
		}
	}
	return resultObj;
}
